import { render, staticRenderFns } from "./resend-activation.vue?vue&type=template&id=d250afdc&scoped=true"
import script from "./resend-activation.vue?vue&type=script&lang=js"
export * from "./resend-activation.vue?vue&type=script&lang=js"
import style0 from "./resend-activation.vue?vue&type=style&index=0&id=d250afdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d250afdc",
  null
  
)

export default component.exports